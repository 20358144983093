import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppContainerWrapper from './features/AppContainer/AppContainerWrapper';

export const App: FC = () => (
  <BrowserRouter>
    <AppContainerWrapper />
  </BrowserRouter>
);
