import styled, { keyframes } from 'styled-components/macro';

const changeOpacity = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const Rect = styled.rect`
  animation-name: ${changeOpacity};
  animation-delay: ${({ delay }: { delay?: string }) => delay};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;
