import React, { Suspense } from 'react';
import { MsalProvider } from '@azure/msal-react';

import { pca } from 'shared/services/authProvider';
import {
  getFromLocalStorage,
  hasTimeExpired
} from '../LogoutWarningPopup/LogoutWarningPopup.helpers';
import { SESSION_EXPIRES_AT } from '../../shared/constants/autologout.const';
import { getWindow, signOut } from './AuthUtils';
import { Loader } from '../../components/Loader/Loader';
import AppContainerContent from './AppContainerContent';

const AppContainerWrapper = () => {
  const host = getWindow().location.hostname;
  const isLocalDevelopment =
    host === 'localhost' ||
    host === '127.0.0.1' ||
    process.env.REACT_APP_SBP_ADFS ||
    process.env.REACT_APP_LOCAL_BUILD;
  const storageLogoutTimeout = getFromLocalStorage(SESSION_EXPIRES_AT);

  if (
    storageLogoutTimeout &&
    hasTimeExpired(storageLogoutTimeout) &&
    !isLocalDevelopment
  ) {
    signOut();
  }

  return (
    <Suspense fallback={<Loader absoluteCentered={true} />}>
      <MsalProvider instance={pca}>
        <AppContainerContent />
      </MsalProvider>
    </Suspense>
  );
};

export default AppContainerWrapper;
