export const POPUP_TIMEOUT = 'POPUP_TIMEOUT';

export const LOGOUT_TIMEOUT = 'LOGOUT_TIMEOUT';

export const SESSION_ID = 'SESSION_ID';

export const USER_ACTIVITY = 'USER_ACTIVITY';

export const KEEP_ALIVE_TIMEOUT = 'KEEP_ALIVE_TIMEOUT';

export const SESSION_EXPIRED = 'SESSION_EXPIRED';

export const SESSION_EXPIRES_AT = 'SESSION_EXPIRES_AT';

export const OIDC = 'oidc';
