import React, { FC } from 'react';
import {
  SvgIcon as MuiSvgIcon,
  SvgIconProps,
  withStyles,
  WithStyles
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { customSvgIconStyle } from './SvgIcon.helpers';

type svgProps = {
  fontSize?: number | string;
};

type IconType = SvgIconProps & {
  customIcon: () => JSX.Element;
  svgProps?: svgProps;
  className?: string;
};

const useStyles = makeStyles({
  root: {
    fontSize: ({ fontSize }: svgProps) => fontSize || 24
  }
});

const CustomSvgIcon: FC<IconType & WithStyles<typeof customSvgIconStyle>> = ({
  customIcon: CustomIcon,
  svgProps = {},
  className = '',
  classes: customClasses,
  ...props
}) => {
  const classes = useStyles(svgProps);
  const customClass = customClasses && customClasses[className];

  return (
    <MuiSvgIcon classes={classes} className={customClass} {...props}>
      <CustomIcon />
    </MuiSvgIcon>
  );
};

export const SvgIcon = withStyles(customSvgIconStyle)(CustomSvgIcon);
