import React, { FC } from 'react';
import cs from 'classnames';

import { iconType } from 'shared/icons';
import { SvgIcon, SvgIconClassName } from 'components/SvgIcon';
import { DivWrapper } from './Loader.styles';

interface Props {
  className?: string;
  centered?: boolean;
  selectLoader?: boolean;
  absoluteCentered?: boolean;
  trackerWidget?: boolean;
  fixed?: boolean;
  inputField?: true;
}

export const Loader: FC<Props> = ({
  className = '',
  centered,
  selectLoader,
  absoluteCentered,
  trackerWidget,
  fixed,
  inputField
}) => (
  <DivWrapper
    className={cs(
      className,
      { centered },
      { selectLoader },
      { absoluteCentered },
      { trackerWidget },
      { fixed },
      { inputField }
    )}
  >
    <SvgIcon
      customIcon={iconType.LOADER_ICON}
      className={SvgIconClassName.LOADER_ICON}
    />
  </DivWrapper>
);
