import { PublicClientApplication } from '@azure/msal-browser';

export const { APP_CONFIG } = window;
// Msal Configurations
const config = {
  auth: {
    authority: `${APP_CONFIG.AUTHORITY_URL}/${APP_CONFIG.AAD_TENANT_ID}`,
    clientId: APP_CONFIG.AAD_CLIENT_ID,
    redirectUri: APP_CONFIG.APP_URL,
    postLogoutRedirectUri: APP_CONFIG.APP_URL
  },
  cache: {
    storeAuthStateInCookie: true
  }
};
// Authentication Parameters
export const loginRequest = {
  scopes: ['user.read']
};

export const pca = new PublicClientApplication(config);
