import { useMsal } from '@azure/msal-react';

import { MSAL_COOKIE_KEY } from 'shared/constants/common.const';

export const getWindow = (): any => window;

export const signOut = (): void => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  document.cookie = `${MSAL_COOKIE_KEY}=;path=/;secure`;
  localStorage.clear();
};
