import { createMuiTheme } from '@material-ui/core/styles/';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const defaultTheme: ThemeOptions = createMuiTheme({
  palette: {
    common: {
      black: '#000',
      white: '#fff'
    },
    primary: {
      main: '#007cb0',
      dark: '#007cb0',
      contrastText: '#fff'
    },
    secondary: {
      main: '#0086d7'
    },
    error: {
      main: '#DA291C'
    },
    warning: {
      main: '#e68b02'
    },
    text: {
      primary: '#000000',
      secondary: '#63666A'
    }
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    button: {
      fontSize: '15px',
      fontWeight: 600
    }
  },
  shape: {
    borderRadius: 2
  }
});
