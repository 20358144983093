import { addSeconds, isSameSecond } from 'date-fns';

import { SESSION_ID } from 'shared/constants/autologout.const';
import { isAfter } from 'shared/utils/date.utils';
// TODO: move functions to local storage utils
export function getFromLocalStorage<T = null>(key: string): T | null {
  const localStorageValue = localStorage.getItem(key);
  return localStorageValue ? JSON.parse(localStorageValue) : null;
}

export const getSessionId = () => getFromLocalStorage(SESSION_ID);

export const removeFromLocalStorage = (key: string) =>
  localStorage.removeItem(key);

export const setToLocalStorage = (key: string, value: any) =>
  localStorage.setItem(key, JSON.stringify(value));

export const calculateEndTime = (time = 0) => {
  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const localISOTime = new Date(Date.now() - tzoffset);
  return addSeconds(localISOTime, time).toISOString().replace('Z', '');
};

export const hasTimeExpired = (timeString: string) => {
  return (
    isAfter(new Date(), new Date(timeString)) ||
    isSameSecond(new Date(), new Date(timeString))
  );
};

export const getDifferenceInSecondsFromNow = (
  dateStringToSubtractSeconds: string
) => {
  const startDate = new Date();
  const endDate = new Date(dateStringToSubtractSeconds);
  return Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
};
