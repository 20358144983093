import React, { lazy } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { ThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { getAppTheme } from '../../theme';
import { getWindow } from './AuthUtils';

const AppContainerContent = () => {
  const appTheme = getAppTheme('default');
  const isAuthenticated = useIsAuthenticated();
  const host = getWindow().location.hostname;
  const isLocalDevelopment =
    host === 'localhost' ||
    host === '127.0.0.1' ||
    process.env.REACT_APP_SBP_ADFS ||
    process.env.REACT_APP_LOCAL_BUILD;

  let renderedMainPage;
  if (isAuthenticated) {
    const AppContainer = lazy(() => import('./AppContainer'));
    renderedMainPage = <AppContainer />;
  } else {
    const UnauthorizedRoutes = lazy(
      () => import('../Routes/UnauthorizedRoutes')
    );
    renderedMainPage = (
      <ThemeProvider theme={appTheme}>
        <StyledThemeProvider theme={appTheme}>
          <UnauthorizedRoutes />
        </StyledThemeProvider>
      </ThemeProvider>
    );
    if (isLocalDevelopment) {
      const AppContainer = lazy(() => import('./AppContainer'));
      renderedMainPage = <AppContainer />;
    }
  }
  return renderedMainPage;
};

export default AppContainerContent;
