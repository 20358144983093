import styled from 'styled-components/macro';

const DivWrapper = styled.div`
  &.centered {
    width: 100%;
    text-align: center;
  }

  &.absoluteCentered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.selectLoader {
    position: absolute;
    right: 0;
  }

  &.trackerWidget {
    margin-top: 10px;
  }

  &.fixed {
    position: fixed;
  }

  &.inputField {
    position: absolute;
    top: 15px;
    right: -5px;
  }
`;

export { DivWrapper };
