import {
  format as formatFn,
  addMonths as addMonthsFn,
  addDays as addDaysFn,
  addYears as addYearsFn,
  isSameDay as isSameDayFn,
  isAfter as isAfterFn,
  isBefore as isBeforeFn,
  subDays as subDaysFn,
  subMonths as subMonthFn,
  subYears as subYearsFn,
  parseISO
} from 'date-fns';

import { DATE_FORMAT } from 'shared/constants/common.const';

const parse = (date: Date | string): Date => {
  return typeof date === 'string' ? parseISO(date.toString()) : date;
};

export const formatString = (
  date: SBP.Nullable<Date> | string,
  dateFormat = DATE_FORMAT
): string => {
  if (!date) {
    return '';
  }

  return format(new Date(date), dateFormat);
};

export const format = (date: Date, dateFormat = DATE_FORMAT): string =>
  formatFn(parse(date), dateFormat);

export const addMonths = (date: Date, amount: number): Date =>
  addMonthsFn(parse(date), amount);

export const addDays = (date: Date, amount: number): Date =>
  addDaysFn(parse(date), amount);

export const addYears = (date: Date, amount: number): Date =>
  addYearsFn(parse(date), amount);

export const isSameDay = (dateLeft: Date, dateRight: Date): boolean =>
  isSameDayFn(parse(dateLeft), parse(dateRight));

export const isAfter = (dateLeft: Date, dateRight: Date): boolean =>
  isAfterFn(parse(dateLeft), parse(dateRight));

export const isBefore = (dateLeft: Date, dateRight: Date): boolean =>
  isBeforeFn(parse(dateLeft), parse(dateRight));

export const subDays = (date: Date, amount: number): Date =>
  subDaysFn(parse(date), amount);

export const subMonth = (date: Date, amount: number): Date =>
  subMonthFn(parse(date), amount);

export const subYears = (date: Date, amount: number): Date =>
  subYearsFn(parse(date), amount);

export const convertToUTCDate = (date: Date) => {
  return new Date(new Date(date).setHours(12, 0, 0));
};

export const convertToUTC = (date: Date) =>
  convertToUTCDate(date).toISOString();
