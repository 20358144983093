export enum SvgIconClassName {
  LOADER_ICON = 'loaderIcon',
  LOADER_ICON_BTN = 'loaderIconBtn',
  LOADER_ICON_BTN_WHITE = 'loaderIconBtnWhite'
}

export const customSvgIconStyle: any = {
  [SvgIconClassName.LOADER_ICON]: {
    fontSize: 40
  },
  [SvgIconClassName.LOADER_ICON_BTN]: {
    fontSize: 30
  },
  [SvgIconClassName.LOADER_ICON_BTN_WHITE]: {
    fontSize: 30,
    '& rect': {
      fill: '#ffffff'
    }
  }
};
